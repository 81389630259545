import React from "react";
import { DisplayStatus, Alignment } from "../../utils/types/component-configs";
import { string, node, func } from "prop-types";
import RowAlign from "../row-align/RowAlign";
import ColumnAlign from "../column-align/ColumnAlign";
import useTimer from "../../hooks/useTimer";
import { createClasses } from "../../utils/helpers/browser-helper";
import CloseCircleIcon from "../../assets/svgs/solid-circle-xmark.inline.svg";
import Button from "../button/Button";
import SVGIcon from "../svg-icon/SvgIcon";
import styles from "./Notification.module.scss";

type NotificationProps = {
  notificationType?: DisplayStatus;
  onClose: () => void;
  // Will only be applied for success notifications
  autoDismiss?: boolean;
  showDismissText?: boolean;
  extraClasses?: string;
};

export default function Notification({
  notificationType = DisplayStatus.SUCCESS,
  children,
  onClose,
  autoDismiss,
  showDismissText,
  extraClasses,
}: React.PropsWithChildren<NotificationProps>) {
  let shouldStartTimer = false;
  if (autoDismiss) {
    shouldStartTimer = true;
  }

  const { secondsLeft } = useTimer({
    countdownSeconds: 5,
    startTimer: shouldStartTimer,
    onCountdownComplete: onClose,
  });

  const notificationClasses = [
    styles.notification,
    styles.px3,
    styles.py3,
    styles.isSize7,
  ];
  switch (notificationType) {
    case DisplayStatus.SUCCESS:
      notificationClasses.push(styles.isSuccess);
      break;
    case DisplayStatus.INFO:
      notificationClasses.push(styles.isInfo);
      break;
    case DisplayStatus.PRIMARY:
      notificationClasses.push(styles.isPrimary);
      break;
    case DisplayStatus.WARN:
      notificationClasses.push(styles.isWarning);
      break;
    case DisplayStatus.ERROR:
      notificationClasses.push(styles.isDanger);
      break;
    default:
      break;
  }

  if (extraClasses) {
    notificationClasses.push(extraClasses);
  }

  function notificationCloseClicked(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  }

  return (
    <ColumnAlign classes={createClasses(notificationClasses)}>
      <RowAlign
        horizontalAlign={Alignment.BETWEEN}
        verticalAlign={Alignment.START}
        classes={styles.notificationTextContainer}
      >
        {children}
        <Button
          buttonClick={notificationCloseClicked}
          type="button"
          classes={createClasses([styles.closeButtonContainer, styles.ml1])}
        >
          <SVGIcon
            iconProps={{
              className: createClasses([styles.deleteButton]),
            }}
          >
            {(svgProps) => <CloseCircleIcon {...svgProps} />}
          </SVGIcon>
        </Button>
      </RowAlign>
      {showDismissText && shouldStartTimer && (
        <RowAlign
          horizontalAlign={Alignment.END}
          classes={createClasses([styles.dismissText])}
        >
          <span>Closes in {secondsLeft} seconds</span>
        </RowAlign>
      )}
    </ColumnAlign>
  );
}

Notification.propTypes = {
  notificationType: string,
  onClose: func,
  children: node.isRequired,
};
