import {
  TaskActivityDetails,
  TaskActivityImpactDetails,
  TaskActivityUnitDetails,
} from "../../graphql/types/common-task";

export enum ImpactCategoryInput {
  ALL = "ALL",
  CARBON = "CARBON",
  WATER = "WATER",
  COMBINED_WASTE = "COMBINED_WASTE",
  DRY_WASTE = "DRY_WASTE",
  WET_WASTE = "WET_WASTE",
  E_WASTE = "E_WASTE",
  MEDICAL_WASTE = "MEDICAL_WASTE",
  PLASTIC_WASTE = "PLASTIC_WASTE",
}

export type TaskActivityUnitDetailsInput = Omit<
  TaskActivityUnitDetails,
  "__typename"
>;

export type TaskActivityImpactDetailsInput = Omit<
  TaskActivityImpactDetails,
  "__typename"
>;

export type TaskActivityDetailsInput = Omit<
  TaskActivityDetails,
  "__typename" | "taskActivityUnits" | "taskActivityImpacts"
> & {
  taskActivityUnits: Array<TaskActivityUnitDetailsInput>;
  taskActivityImpacts: Array<TaskActivityImpactDetailsInput>;
};
