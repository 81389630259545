import React from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { ApolloProvider } from "@apollo/client/react";
import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client/link/http";
import { onError } from "@apollo/client/link/error";
import { TASK_ID, UPDATE_ID } from "../../graphql/constants/type-id-fields";
import {
  USER_TASK_SUMMARY,
  COMMUNITY_TASK_SUMMARY,
  USER_TASK_DETAILS,
  USER_PERFORMED_TASK_DETAILS,
} from "../../graphql/constants/type-names";
import { transformCommunityTasksSummaryResponse } from "../../components/community/helpers/process-summary-response";
import {
  completeUserLogout,
  getTokenForApiRequest,
} from "../../utils/helpers/auth-helper";
import TokenManager from "../token-manager/TokenManager";
import { OPERATIONS_IGNORE_TOKEN_CHECK } from "../../utils/constants/login";
import ErrorBoundary from "../../molecules/error-boundary/ErrorBoundary";
import { ROOT_GET_COMMUNITY_TASKS_SUMMARY } from "../../graphql/constants/type-root-fields";
import { hasAuthenticationError } from "../../utils/helpers/query-error-processing";

function getAuthLink(ssrMode: boolean) {
  return setContext(async (operation, { headers }) => {
    let token;
    // Skip token check for server env and for select operations
    if (
      !ssrMode &&
      !OPERATIONS_IGNORE_TOKEN_CHECK.has(operation?.operationName || "")
    ) {
      token = await getTokenForApiRequest();
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
}

function getErrorLink(client: ApolloClient<any>) {
  return onError(({ operation, graphQLErrors, networkError }) => {
    const { response } = operation.getContext() || {};
    const hasAuthError = hasAuthenticationError({ graphQLErrors });

    // Unauthorized, redirect to login page
    if (response?.status === 401 || hasAuthError) {
      completeUserLogout(client, { autoLogout: true });
    }

    // TODO add a logger
  });
}

type OnAppInitLoadParams = {
  ssrMode: boolean;
  fetchForClient?: (
    url: RequestInfo | URL,
    init?: RequestInit
  ) => Promise<Response>;
};

type AppRendererProps = {
  element: JSX.Element;
};

export default function onAppInitLoad({
  ssrMode,
  fetchForClient,
}: OnAppInitLoadParams) {
  return function AppRenderer({ element }: AppRendererProps) {
    const httpLink = createHttpLink({
      uri: process.env.GRAPH_QL_URI,
      fetch: fetchForClient,
      credentials: "include",
    });

    const client = new ApolloClient({
      ssrMode,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              [ROOT_GET_COMMUNITY_TASKS_SUMMARY]: {
                keyArgs: false,
                merge: transformCommunityTasksSummaryResponse,
              },
            },
          },
          [USER_TASK_SUMMARY]: {
            keyFields: [TASK_ID],
          },
          [USER_TASK_DETAILS]: {
            keyFields: [TASK_ID],
          },
          [COMMUNITY_TASK_SUMMARY]: {
            keyFields: [TASK_ID],
          },
          [USER_PERFORMED_TASK_DETAILS]: {
            keyFields: [UPDATE_ID],
          },
        },
      }),
    });

    const authLink = getAuthLink(ssrMode);
    const errorLink = getErrorLink(client);
    client.setLink(errorLink.concat(authLink.concat(httpLink)));

    return (
      <ApolloProvider client={client}>
        <TokenManager />
        <ErrorBoundary>{element}</ErrorBoundary>
      </ApolloProvider>
    );
  };
}
