export const USER_TASK_SUMMARY = "UserTaskSummary";

export const GET_USER_TASKS_SUMMARY_RESPONSE = "GetUserTasksSummaryResponse";

export const GET_USER_TASK_RESPONSE = "GetUserTaskResponse";

export const GET_COMMUNITY_TASK_SUMMARY_RESPONSE =
  "CommunityPaginatedTasksResponse";

export const USER_TASK_DETAILS = "UserTaskDetails";

export const DASHBOARD_DATA = "DashboardData";

export const SUSTAINABILITY_PARAMS = "SustainabilityParameters";

export const REGISTERED_USER_DATA = "RegisteredUserData";

export const USER_AUTH_DATA = "UserAuthData";

export const USER_ACCOUNT_DATA = "UserAccountData";

export const COMMUNITY_TASK_SUMMARY = "CommunityTaskSummary";

export const USER_PERFORMED_TASK_DETAILS = "PerformedTasksDetails";
