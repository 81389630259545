import React from "react";
import BugIcon from "../../assets/svgs/solid-bug.inline.svg";
import useTokenStatus from "../../hooks/useTokenStatus";
import {
  DisplaySize,
  DisplayStatus,
  Alignment,
} from "../../utils/types/component-configs";
import { getHomePageUrl } from "../../utils/helpers/auth-helper";
import { createClasses } from "../../utils/helpers/browser-helper";
import ColumnAlign from "../../atoms/column-align/ColumnAlign";
import SVGIcon from "../../atoms/svg-icon/SvgIcon";
import styles from "./ErrorPage.module.scss";
import Button from "../../atoms/button/Button";

type ErrorPageProps = {
  errorText: string;
};

export default function ErrorPage({ errorText }: ErrorPageProps) {
  const tokenExists = useTokenStatus({ clientKey: "ERROR_PAGE" });

  function routeToHomePage() {
    window.location.replace(getHomePageUrl(tokenExists));
  }

  return (
    <ColumnAlign
      classes={createClasses([styles.mx4, "base-page-container"])}
      verticalAlign={Alignment.CENTER}
      horizontalAlign={Alignment.CENTER}
    >
      <SVGIcon
        iconSize={DisplaySize.EXTRA_LARGE}
        iconStatus={DisplayStatus.ERROR}
      >
        {(svgProps) => <BugIcon {...svgProps} />}
      </SVGIcon>
      <ColumnAlign
        classes={createClasses([
          styles.container,
          styles.hasTextCentered,
          styles.mt4,
        ])}
        horizontalAlign={Alignment.CENTER}
      >
        <p>
          We have encountered an unexpected issue. Please try again and if the
          issue persists, contact us. Mention the reason as: {errorText}
        </p>
        <Button
          buttonClick={routeToHomePage}
          buttonRole={DisplayStatus.PRIMARY}
          sizes={DisplaySize.MEDIUM}
          classes={styles.mt2}
        >
          Go to home page
        </Button>
      </ColumnAlign>
    </ColumnAlign>
  );
}
