import { BaseGraphQlTypeData } from "../../utils/types/app-types";

export enum UsagePeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export interface SustainabilityParameters extends BaseGraphQlTypeData {
  carbon?: number;
  water?: number;
  plasticWaste?: number;
  medicalWaste?: number;
  eWaste?: number;
  wetWaste?: number;
  dryWaste?: number;
}

export interface HistoricalSustainabilityParameters
  extends SustainabilityParameters {
  periodStart: string;
  periodEnd: string;
  currentPeriod?: boolean;
}
