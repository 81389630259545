import { SVGIconProps } from "../../atoms/svg-icon/SvgIcon";
import { UsagePeriod } from "../../graphql/types/user-usage";
import { JavaScriptObject } from "./app-types";
import { ImpactCategoryInput } from "./task-activity-input-types";

export type GlobalRadioFilters = {
  impactCategory: ImpactCategoryInput;
  usagePeriod: UsagePeriod;
};

export type GlobalDateFilters = {
  day: Date;
};

export type GlobalDateFiltersStorage = {
  day: string;
};

export type GlobalCheckboxFilters = {
  fetchMyTasks?: boolean;
};

// Type for use throughout the application for all purposes
export type GlobalFilters = GlobalRadioFilters &
  GlobalDateFilters &
  GlobalCheckboxFilters;

export type GlobalFiltersStorage = GlobalRadioFilters &
  GlobalDateFiltersStorage &
  GlobalCheckboxFilters;

export enum FilterModal {
  PAGE = "PAGE",
  DATE = "DATE",
}

export enum FilterInputs {
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  DAY_PICKER = "DAY_PICKER",
}

export type AppliedFilterDataItem = {
  summary: string;
  canReplaceFilterButtonText?: boolean;
};

export type AppliedGlobalFilters = {
  [Property in keyof GlobalFilters]?: AppliedFilterDataItem;
};

export type FilterInputRadioValue = JavaScriptObject<string | undefined>;

export type FilterInputCheckboxValue = JavaScriptObject<boolean>;

export type FilterInputDayValue = JavaScriptObject<Date>;

export type FilterViewSectionInputRadio<T extends FilterInputRadioValue> = {
  inputType: FilterInputs.RADIO;
  mapping: Map<T[keyof T], string>;
  filterVariable: keyof T;
  inputName: string;
};

export type FilterViewSectionInputCheckbox<T extends FilterInputCheckboxValue> =
  {
    inputType: FilterInputs.CHECKBOX;
    filterVariable: keyof T;
    mapping: Map<string, string>;
    inputName: string;
  };

export type FilterViewSectionInputDay<T extends FilterInputDayValue> = {
  inputType: FilterInputs.DAY_PICKER;
  filterVariable: keyof T;
};

type FilterSectionInputData<
  T extends FilterInputRadioValue,
  U extends FilterInputDayValue,
  V extends FilterInputCheckboxValue,
> =
  | FilterViewSectionInputRadio<T>
  | FilterViewSectionInputDay<U>
  | FilterViewSectionInputCheckbox<V>;

export type FilterSectionData<
  T extends FilterInputRadioValue = {},
  U extends FilterInputDayValue = {},
  V extends FilterInputCheckboxValue = {},
> = {
  filterType: FilterModal;
  title: string;
  icon: SVGIconProps["children"];
  disabled?: boolean;
} & FilterSectionInputData<T, U, V>;

export type OnFilterChangeCallback<
  T extends FilterInputRadioValue = {},
  U extends FilterInputDayValue = {},
  V extends FilterInputCheckboxValue = {},
> = (
  modifiedFilters: T & U & V
) => Array<FilterSectionData<T, U, V>> | undefined;
