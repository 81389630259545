import React from "react";
import {
  FilterInputs,
  FilterModal,
  FilterSectionData,
} from "../types/filter-types";
import {
  GlobalDateFilters,
  GlobalFilters,
  GlobalRadioFilters,
} from "../types/filter-types";
import SmogIcon from "../../assets/svgs/solid-smog.inline.svg";
import CalendarIcon from "../../assets/svgs/solid-calendar-day.inline.svg";
import TimelineIcon from "../../assets/svgs/solid-timeline.inline.svg";
import { getDateWithNoTime } from "../helpers/date-helpers";
import { IMPACT_CATEGORY_LABELS } from "./task-activities";
import { USAGE_PERIOD_LABELS } from "./usage-period";
import { UsagePeriod } from "../../graphql/types/user-usage";
import { ImpactCategoryInput } from "../types/task-activity-input-types";

export const DEFAULT_FILTERS: GlobalFilters = {
  day: getDateWithNoTime(),
  impactCategory: ImpactCategoryInput.ALL,
  usagePeriod: UsagePeriod.DAILY,
};

// TODO add more options as and when the parameters are supported
export const FILTER_IMPACT_MAPPER = new Map<ImpactCategoryInput, string>([
  [ImpactCategoryInput.ALL, "All"],
  [
    ImpactCategoryInput.CARBON,
    IMPACT_CATEGORY_LABELS[ImpactCategoryInput.CARBON],
  ],
  [
    ImpactCategoryInput.WATER,
    IMPACT_CATEGORY_LABELS[ImpactCategoryInput.WATER],
  ],
]);

export const IMPACT_FILTER_CONFIG: FilterSectionData<GlobalRadioFilters> = {
  filterType: FilterModal.PAGE,
  title: "Ecological impact",
  icon: (props) => <SmogIcon {...props} />,
  filterVariable: "impactCategory",
  inputType: FilterInputs.RADIO,
  inputName: "impact-category",
  mapping: FILTER_IMPACT_MAPPER,
};

export const FILTER_USAGE_PERIOD_MAPPER = new Map<UsagePeriod, string>([
  [UsagePeriod.DAILY, USAGE_PERIOD_LABELS[UsagePeriod.DAILY]],
  [UsagePeriod.WEEKLY, USAGE_PERIOD_LABELS[UsagePeriod.WEEKLY]],
  [UsagePeriod.MONTHLY, USAGE_PERIOD_LABELS[UsagePeriod.MONTHLY]],
]);

export const DAY_FILTER_CONFIG: FilterSectionData<{}, GlobalDateFilters> = {
  filterVariable: "day",
  icon: (props) => <CalendarIcon {...props} />,
  inputType: FilterInputs.DAY_PICKER,
  title: "Choose date",
  filterType: FilterModal.DATE,
};

export const USAGE_PERIOD_FILTER_CONFIG: FilterSectionData<GlobalRadioFilters> =
  {
    filterType: FilterModal.DATE,
    title: "Ecological impact period",
    icon: (props) => <TimelineIcon {...props} />,
    filterVariable: "usagePeriod",
    inputType: FilterInputs.RADIO,
    inputName: "usage-period",
    mapping: FILTER_USAGE_PERIOD_MAPPER,
  };
