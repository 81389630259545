import { navigate } from "gatsby";
import { SESSION_STORAGE_START_PAGE } from "../constants/app-common";
import {
  AS_CHILD_SEARCH_PARAM,
  CLIENT_ROUTE_PREFIX,
  DASHBOARD_URL,
  FROM_PAGE_SEARCH_PARAM,
  HOME_PAGE_URL,
} from "../constants/page";
import { getToken } from "./auth-helper";
import { isServerEnv } from "./browser-helper";

type BackNavParams =
  | {
      replaceOnRoute?: boolean;
    }
  | undefined;

export function goToHomePage({ replaceOnRoute }: BackNavParams = {}) {
  if (getToken()) {
    navigate(DASHBOARD_URL, {
      replace: replaceOnRoute,
    });
    return;
  }

  navigate(HOME_PAGE_URL);
}

export function goBackIfAppPageExists(backNavParams: BackNavParams = {}) {
  const savedPageName = sessionStorage.getItem(SESSION_STORAGE_START_PAGE);
  if (savedPageName) {
    navigate(-1);
    return;
  }

  goToHomePage(backNavParams);
}

type CreateQueryParams = {
  key: string;
  value: string | number | boolean | null | undefined;
};

type CreateQueryParamsArgs = Array<CreateQueryParams>;

export function createQueryStringFromSearchParams(
  searchParams: URLSearchParams
) {
  const queryString = searchParams.toString();

  if (queryString === "") {
    return "";
  }

  return `?${queryString}`;
}

export function createQueryParams(queryParams: CreateQueryParamsArgs) {
  const searchParams = new URLSearchParams();
  queryParams.forEach(({ key, value }) => {
    if (value === null || value === undefined) {
      return;
    }
    searchParams.set(key.trim(), value.toString().trim());
  });

  return createQueryStringFromSearchParams(searchParams);
}

export function getCurrentRouteAsUrl() {
  const { href } = window.location;
  return new URL(href);
}

type CreateScrollRestorationKeyParams = { pathname: string };

export function createScrollRestorationKey(
  scrollRestoreParams?: CreateScrollRestorationKeyParams
) {
  if (isServerEnv()) {
    return "";
  }
  return `@@app-scroll||${
    scrollRestoreParams?.pathname ?? window.location.pathname
  }`;
}

export function isClientOnlyRoute() {
  const clientMatchRegEx = new RegExp(`^${CLIENT_ROUTE_PREFIX}/`);
  const matchResult = window.location.pathname.match(clientMatchRegEx);
  return Array.isArray(matchResult) && matchResult.length > 0;
}

export function fixGatsbyPath(pathName?: string) {
  if (typeof pathName !== "string") {
    return "";
  }

  // Remove the trailing slash, if the path was only / then set default value
  // as / because replace will set it to blank string
  return pathName.replace(/\/$/, "") || "/";
}

type CreateComponentNavigationRouteParams = {
  searchParams?: URLSearchParams;
  routeUrl: string;
  asChild?: boolean;
  fromPage?: string;
};

export function createComponentNavigationRoute({
  searchParams = new URLSearchParams(),
  routeUrl,
  asChild,
  fromPage,
}: CreateComponentNavigationRouteParams) {
  if (asChild) {
    searchParams.set(AS_CHILD_SEARCH_PARAM, "true");
  }
  if (fromPage) {
    searchParams.set(FROM_PAGE_SEARCH_PARAM, fromPage);
  }
  return `${routeUrl}${createQueryStringFromSearchParams(searchParams)}`;
}

export function constructCurrentUrlWithoutProtocol() {
  const { pathname, search } = window.location;
  return `${pathname}${search}`;
}
