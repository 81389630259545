// Status for display of colors
export enum DisplayStatus {
  DEFAULT = "default",
  PRIMARY = "primary",
  SUCCESS = "success",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

// Sizes for display
export enum DisplaySize {
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  NORMAL = "normal",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extra-large",
}

// Status for signifying ongoing status for data saved locally
export enum LoadStatus {
  SUCCESS = "LOAD_SUCCESS",
  PROGRESS = "LOAD_PROGRESS",
  ERROR = "LOAD_ERROR",
  CACHE_CLEAR = "CACHE_CLEAR",
}

// Alignment options
export enum Alignment {
  HORIZONTAL = "ALIGN_HORIZONTAL",
  VERTICAL = "ALIGN_VERTICAL",
  RIGHT = "ALIGN_RIGHT",
  LEFT = "ALIGN_LEFT",
  START = "ALIGN_START",
  END = "ALIGN_END",
  CENTER = "ALIGN_CENTER",
  BETWEEN = "ALIGN_BETWEEN",
  STRETCH = "ALIGN_STRETCH",
}

// Direction constants
export enum Direction {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}
