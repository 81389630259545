import { ApolloClient } from "@apollo/client/core";
import { LoadStatus } from "../types/component-configs";
import { TOKEN_CACHE_KEY } from "../constants/login";
import { HOME_PAGE_URL, DASHBOARD_URL } from "../constants/page";
import { RefreshTokenData, TokenData } from "../types/app-types";
import {
  clearCacheData,
  CacheData,
  readFromCache,
  writeToCache,
  attachListeners,
  removeListeners,
} from "./cache-listeners";
import {
  clearNativeStorage,
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "./storage-helpers";
import { REFRESH_TOKEN_PROGRESS_CACHE_KEY } from "../constants/login";

type SaveAuthTokens = {
  accessToken: string;
  refreshToken: string;
};

type LoginUserParams = SaveAuthTokens & {
  authType?: string;
};

type FetchAuthTokens = {
  [Property in keyof SaveAuthTokens]?: SaveAuthTokens[Property];
};

type CompleteUserLogoutConfigParam = {
  autoLogout?: boolean;
};

type RefreshTokenCheckProgressParams = {
  status: string;
};

const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export function getTokenFromNativeStorage(): FetchAuthTokens {
  return {
    accessToken: getItemFromLocalStorage(ACCESS_TOKEN_KEY) || undefined,
    refreshToken: getItemFromLocalStorage(REFRESH_TOKEN_KEY) || undefined,
  };
}

export function getTokenDataFromCachedData(cachedData?: CacheData<TokenData>) {
  if (cachedData && cachedData.data) {
    const { data } = cachedData;
    return data.token;
  }
}

function getTokenFromCache() {
  return getTokenDataFromCachedData(
    readFromCache({
      cacheKey: TOKEN_CACHE_KEY,
    })
  );
}

export function getToken() {
  const token = getTokenFromCache();
  if (token) {
    return token;
  }

  const { accessToken } = getTokenFromNativeStorage();
  return accessToken;
}

export function getTokenForApiRequest() {
  // Create random key
  const clientKey = `${Date.now()}${Math.floor(Math.random() * 5)}`;
  return new Promise((resolve) => {
    function cacheChangeHandler(cachedData?: CacheData) {
      if (cachedData?.loadStatus !== LoadStatus.PROGRESS) {
        const token = getToken();
        resolve(token);
      }
    }

    attachListeners(
      {
        cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
        clientKey,
      },
      { cacheChangeHandler }
    );
    cacheChangeHandler(
      readFromCache({
        cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
      })
    );
  }).then((token) => {
    removeListeners({
      cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
      clientKey,
    });
    return token;
  });
}

function isTokenValid(token: string) {
  return token.trim() !== "";
}

export function saveTokenToNativeStorage(tokenData: SaveAuthTokens) {
  const { accessToken, refreshToken } = tokenData;
  if (!isTokenValid(accessToken) || !isTokenValid(refreshToken)) {
    return;
  }

  setItemInLocalStorage(ACCESS_TOKEN_KEY, accessToken);
  setItemInLocalStorage(REFRESH_TOKEN_KEY, refreshToken);
}

export function saveTokenInCache(token: string) {
  if (!isTokenValid(token)) {
    return;
  }

  writeToCache<TokenData>({
    cacheKey: TOKEN_CACHE_KEY,
    data: {
      token,
    },
  });
}

export function saveToken(tokenData: SaveAuthTokens) {
  saveTokenToNativeStorage(tokenData);
  saveTokenInCache(tokenData.accessToken);
}

export function saveOauthTypeToNativeStorage(type: string) {
  setItemInLocalStorage("oauth_type", type);
}

export function getHomePageUrl(tokenExists: boolean) {
  if (tokenExists) {
    return DASHBOARD_URL;
  }

  return HOME_PAGE_URL;
}

export function completeUserLogin({
  accessToken,
  refreshToken,
  authType,
}: LoginUserParams) {
  saveToken({ accessToken, refreshToken });

  if (authType) {
    saveOauthTypeToNativeStorage(authType);
  }
}

export function completeUserLogout(
  client: ApolloClient<unknown>,
  { autoLogout }: CompleteUserLogoutConfigParam = {}
) {
  client.clearStore();
  clearNativeStorage();
  clearCacheData({ notifyAllListeners: autoLogout });
}

export function refreshTokenCheckProgress({
  status,
}: RefreshTokenCheckProgressParams) {
  const refreshTokenData: Partial<RefreshTokenData> = {};
  if (status === LoadStatus.SUCCESS || status === LoadStatus.ERROR) {
    refreshTokenData.firstCheck = false;
  } else if (status === LoadStatus.PROGRESS) {
    const cachedData = readFromCache<RefreshTokenData>({
      cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
    });
    // Nothing in cache as of now, consider it a first time check
    if (!cachedData?.loadStatus) {
      refreshTokenData.firstCheck = true;
    }
  }

  writeToCache<RefreshTokenData>({
    cacheKey: REFRESH_TOKEN_PROGRESS_CACHE_KEY,
    data: refreshTokenData,
    status,
  });
}
