import React from "react";
import { string, func } from "prop-types";
import styles from "./SvgIcon.module.scss";
import {
  DisplayStatus,
  DisplaySize,
} from "../../utils/types/component-configs";
import { createClasses } from "../../utils/helpers/browser-helper";

export type SVGIconProps = {
  iconSize?: string;
  iconStatus?: string;
  // TODO SEO consideration
  semantic?: boolean;
  iconProps?: React.SVGProps<SVGSVGElement>;
  children: (params: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export default function SVGIcon({
  iconSize = DisplaySize.SMALL,
  iconStatus,
  semantic,
  iconProps,
  children,
}: SVGIconProps) {
  const iconClasses = [styles.icon];
  if (iconProps?.className) {
    iconClasses.push(iconProps.className);
  }

  switch (iconSize) {
    case DisplaySize.EXTRA_SMALL:
      iconClasses.push(styles.isExtraSmall);
      break;
    case DisplaySize.SMALL:
      iconClasses.push(styles.isSmall);
      break;
    case DisplaySize.MEDIUM:
      iconClasses.push(styles.isMedium);
      break;
    case DisplaySize.LARGE:
      iconClasses.push(styles.isLarge);
      break;
    case DisplaySize.EXTRA_LARGE:
      iconClasses.push(styles.isExtraLarge);
      break;
    default:
      break;
  }

  switch (iconStatus) {
    case DisplayStatus.SUCCESS:
      iconClasses.push(styles.hasTextSuccess);
      break;
    case DisplayStatus.ERROR:
      iconClasses.push(styles.hasTextDanger);
      break;
    case DisplayStatus.WARN:
      iconClasses.push(styles.hasTextWarning);
      break;
    case DisplayStatus.INFO:
      iconClasses.push(styles.hasTextInfo);
      break;
    default:
      break;
  }

  const svgComponentProps: React.SVGProps<SVGSVGElement> = {
    ...iconProps,
    className: createClasses(iconClasses),
  };

  return children(svgComponentProps);
}

SVGIcon.propTypes = {
  iconSize: string,
  iconStatus: string,
  children: func.isRequired,
};
