import React from "react";
import { node, string, func, oneOfType } from "prop-types";
import { ChildrenFunctionOrNode } from "../../utils/types/app-types";
import { pickStylesForColumnAlignBasedOnParams } from "../../utils/helpers/row-column-align";
import styles from "./ColumnAlign.module.scss";
import { PropsWithStyle } from "../../utils/types/app-types";

export type ColumnAlignExtraProps = {
  classes?: string;
  children: ChildrenFunctionOrNode<{ classes: string }>;
};

export type ColumnAlignBaseProps = {
  verticalAlign?: string;
  horizontalAlign?: string;
  fullHeight?: boolean;
  fitHeight?: boolean;
  shouldScroll?: boolean;
};

export default function ColumnAlign({
  children,
  classes,
  verticalAlign,
  horizontalAlign,
  fullHeight,
  fitHeight,
  shouldScroll,
  style,
}: ColumnAlignBaseProps & PropsWithStyle<ColumnAlignExtraProps>) {
  let className = pickStylesForColumnAlignBasedOnParams(
    { verticalAlign, horizontalAlign, fullHeight, fitHeight, shouldScroll },
    styles
  );

  if (classes) {
    className += ` ${classes}`;
  }

  if (typeof children === "function") {
    return children({ classes: className });
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

ColumnAlign.propTypes = {
  children: oneOfType([node, func]).isRequired,
  verticalAlign: string,
  horizontalAlign: string,
  classes: string,
};
