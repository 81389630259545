import React, {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
} from "react";
import { func, string, bool, node } from "prop-types";
import {
  DisplayStatus,
  DisplaySize,
  Alignment,
} from "../../utils/types/component-configs";
import {
  pickStylesForColumnAlignBasedOnParams,
  pickStylesForRowAlignBasedOnParams,
} from "../../utils/helpers/row-column-align";
import styles from "./Button.module.scss";
import { ColumnAlignBaseProps } from "../column-align/ColumnAlign";
import { RowAlignBaseProps } from "../row-align/RowAlign";

export type ButtonBaseProps = {
  buttonClick: (event: MouseEvent<HTMLButtonElement>) => void;
  classes?: string;
  loader?: boolean;
  buttonRole?: string;
  buttonOutline?: boolean;
  fullWidth?: boolean;
  sizes?: string;
  alignDirection?: string;
};

export type ButtonComponentProps = PropsWithChildren<
  ButtonBaseProps & RowAlignBaseProps & ColumnAlignBaseProps
> &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick" | "className">;

const Button = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    {
      children,
      buttonClick,
      classes,
      sizes,
      loader = false,
      buttonRole,
      buttonOutline = false,
      fullWidth = false,
      disabled,
      alignDirection,
      verticalAlign,
      horizontalAlign,
      wrap,
      wrapHorizontalSpace,
      wrapVerticalSpace,
      fullHeight,
      fitHeight,
      shouldScroll,
      ...restbutttonProps
    },
    ref
  ) => {
    const onButtonClick = function (event: MouseEvent<HTMLButtonElement>) {
      event.preventDefault();
      if (buttonClick) {
        buttonClick(event);
      }
    };

    let className = "";

    switch (buttonRole) {
      case DisplayStatus.PRIMARY:
        className += ` ${styles.isPrimary}`;
        break;
      case DisplayStatus.INFO:
        className += ` ${styles.isInfo}`;
        break;
      case DisplayStatus.ERROR:
        className += ` ${styles.isDanger}`;
        break;
      default:
        break;
    }

    switch (sizes) {
      case DisplaySize.SMALL:
        className += ` ${styles.isSmall}`;
        break;
      case DisplaySize.MEDIUM:
        className += ` ${styles.isMedium}`;
        break;
      case DisplaySize.LARGE:
        className += ` ${styles.isLarge}`;
        break;
      default:
        break;
    }

    if (buttonOutline) {
      className += ` ${styles.isOutlined}`;
    }

    if (fullWidth) {
      className += ` ${styles.isFullwidth}`;
    }

    if (loader) {
      className += ` ${styles.isLoading} ${styles.overlayLoader}`;
    }

    // Either some button properties are being used or the button must behave as the default style
    // Add the basic button style
    if (className !== "" || buttonRole === DisplayStatus.DEFAULT) {
      className = `${styles.button} ${className}`;
    }

    if (alignDirection === Alignment.HORIZONTAL) {
      className += ` ${pickStylesForRowAlignBasedOnParams(
        {
          horizontalAlign,
          verticalAlign,
          wrap,
          wrapHorizontalSpace,
          wrapVerticalSpace,
        },
        styles
      )}`;
    } else if (alignDirection === Alignment.VERTICAL) {
      className += ` ${pickStylesForColumnAlignBasedOnParams(
        { horizontalAlign, verticalAlign, fullHeight, fitHeight, shouldScroll },
        styles
      )}`;
    }

    if (classes) {
      className += ` ${classes}`;
    }

    return (
      <button
        disabled={loader || disabled}
        {...restbutttonProps}
        onClick={onButtonClick}
        className={className.trim()}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  buttonClick: func.isRequired,
  classes: string,
  sizes: string,
  loader: bool,
  buttonRole: string,
  buttonOutline: bool,
  fullWidth: bool,
  disabled: bool,
  children: node.isRequired,
};

export default Button;

type ButtonsProps = {
  align?: string;
  classes?: string;
  hasAddons?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  removeBottomSpacing?: boolean;
};

export function Buttons({
  children,
  align,
  hasAddons,
  classes,
  onClick,
  removeBottomSpacing,
}: React.PropsWithChildren<ButtonsProps>) {
  let buttonsClasses = `${styles.buttons} ${styles.buttonsContainer}`;
  switch (align) {
    case Alignment.RIGHT:
      buttonsClasses += ` ${styles.isRight}`;
      break;
    default:
      break;
  }

  if (hasAddons) {
    buttonsClasses += ` ${styles.hasAddons}`;
  }

  if (removeBottomSpacing) {
    buttonsClasses += ` ${styles.removeBottomSpacing}`;
  }

  if (classes) {
    buttonsClasses += ` ${classes}`;
  }

  return (
    <div className={buttonsClasses} onClick={onClick}>
      {children}
    </div>
  );
}

Buttons.propTypes = {
  children: node.isRequired,
  align: string,
  classes: string,
  hasAddons: bool,
};
