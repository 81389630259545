import { useMutation } from "@apollo/client/react/hooks";
import { useEffect } from "react";
import { navigate } from "gatsby";
import {
  REFRESH_TOKEN_USER_SESSION,
  RefreshTokenOperationInput,
  RefreshTokenOperationResponse,
} from "../../components/auth/queries/auth-management/auth-management-queries";
import {
  completeUserLogout,
  getTokenFromNativeStorage,
  refreshTokenCheckProgress,
  saveToken,
} from "../../utils/helpers/auth-helper";
import { LoadStatus } from "../../utils/types/component-configs";
import {
  POST_VERIFY_EMAIL_URL_SEARCH_PARAM,
  VERIFY_EMAIL_URL,
} from "../../utils/constants/page";
import {
  constructCurrentUrlWithoutProtocol,
  createQueryParams,
  fixGatsbyPath,
} from "../../utils/helpers/navigation-helper";

type BeginTokenRefreshParams = {
  redirectEmailVerification?: boolean;
};

export default function TokenManager() {
  const [refreshToken, { client }] = useMutation<
    RefreshTokenOperationResponse,
    RefreshTokenOperationInput
  >(REFRESH_TOKEN_USER_SESSION);

  useEffect(() => {
    async function beginTokenRefresh({
      redirectEmailVerification,
    }: BeginTokenRefreshParams = {}) {
      refreshTokenCheckProgress({ status: LoadStatus.PROGRESS });
      const { refreshToken: savedRefreshToken } = getTokenFromNativeStorage();
      if (!savedRefreshToken) {
        refreshTokenCheckProgress({ status: LoadStatus.SUCCESS });
        return;
      }

      try {
        const result = await refreshToken({
          variables: {
            sessionData: { refreshToken: savedRefreshToken },
          },
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        });
        if (!result?.data?.refreshToken) {
          completeUserLogout(client);
          refreshTokenCheckProgress({ status: LoadStatus.ERROR });
          return;
        }
        saveToken(result.data.refreshToken);

        // Email verification is required and not already on the same page, redirect
        if (
          redirectEmailVerification &&
          result.data.refreshToken.emailVerification &&
          fixGatsbyPath(window.location.pathname) !== VERIFY_EMAIL_URL
        ) {
          navigate(VERIFY_EMAIL_URL);
        }
        refreshTokenCheckProgress({ status: LoadStatus.SUCCESS });
      } catch (refreshTokenError) {
        completeUserLogout(client);
        refreshTokenCheckProgress({ status: LoadStatus.ERROR });
      }
    }
    const intervalId = window.setInterval(
      () => beginTokenRefresh(),
      Number(process.env.AUTH_TOKEN_TIMEOUT)
    );

    // Do it the first time to instantly load the token
    beginTokenRefresh({ redirectEmailVerification: true });

    return () => {
      window.clearInterval(intervalId);
    };
  }, [refreshToken]);

  return null;
}
