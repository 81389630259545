export const HOME_PAGE_URL = "/";

export const CLIENT_ROUTE_PREFIX = "/app";

export const DASHBOARD_URL = `${CLIENT_ROUTE_PREFIX}/dashboard`;

export const LOGIN_URL = "/login";

export const COMMUNITY_URL = "/community";

export const MY_TASKS_URL = `${CLIENT_ROUTE_PREFIX}/my-tasks`;

export const USER_TASK_DETAILS_URL = `${CLIENT_ROUTE_PREFIX}/user-task/details`;

export const USER_TASK_UPDATE_PERFORM_URL = `${CLIENT_ROUTE_PREFIX}/user-task/update-perform`;

export const COMMUNITY_TASK_DETAILS_URL = "/community-task/details";

export const COMMUNITY_TASK_UPDATE_PICK_URL = `/community-task/update-pick`;

export const ADD_USER_TASK_URL = `/user-task/add-task`;

export const COMPANY_INFO_URL = "/company-info";

export const PRIVACY_POLICY_URL = "/privacy-policy";

export const FEEDBACK_URL = `${CLIENT_ROUTE_PREFIX}/feedback`;

export const CAREERS_URL = "/careers";

export const ERROR_URL = `${CLIENT_ROUTE_PREFIX}/error`;

export const VERIFY_EMAIL_URL = `${CLIENT_ROUTE_PREFIX}/verify-email`;

// Page names for internal use
export const HOME_PAGE = "HOME";

export const DASHBOARD_PAGE = "DASHBOARD";

export const LOGIN_PAGE = "LOGIN";

export const ALL_TASKS_PAGE = "ALL_TASKS";

export const MY_TASKS_PAGE = "MY_TASKS";

export const ADD_TASK_PAGE = "ADD_TASK";

export const USER_TASK_DETAILS_PAGE = "USER_TASK_DETAILS";

export const USER_TASK_UPDATE_PERFORM_PAGE = "USER_TASK_UPDATE_PERFORM";

export const COMMUNITY_PAGE = "COMMUNITY";

export const COMMUNITY_TASK_DETAILS_PAGE = "COMMUNITY_TASK_DETAILS";

export const COMMUNITY_TASK_UPDATE_PICK_PAGE = "COMMUNITY_TASK_UPDATE_PICK";

export const COMPANY_INFO_PAGE = "COMPANY_INFO";

export const PRIVACY_POLICY_PAGE = "PRIVACY_POLICY";

export const FEEDBACK_PAGE = "FEEDBACK";

export const CAREERS_PAGE = "CAREERS";

// Route params
export const AS_CHILD_SEARCH_PARAM = "asChild";

export const FROM_PAGE_SEARCH_PARAM = "fromPage";

export const POST_LOGIN_URL_SEARCH_PARAM = "postLoginUrl";

export const POST_VERIFY_EMAIL_URL_SEARCH_PARAM = "postVerifyEmailUrl";

export const ERROR_TEXT_SEARCH_PARAM = "errorText";

export const LOGIN_MODE_PARAM = "mode";

export const LOGIN_EMAIL_PARAM = "email";

// Filter route params

export const DAY_SEARCH_PARAM = "day";

export const IMPACT_CATEGORY_SEARCH_PARAM = "impactCategory";

export const FETCH_MY_TASKS_SEARCH_PARAM = "fetchMyTasks";
