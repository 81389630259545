import { FetchCommunityPaginatedTasksApiResponse } from "../queries/community-task-summary/community-task-list-queries";
import { CommunityTaskSummary } from "../../../graphql/types/community-tasks";

export function transformCommunityTasksSummaryResponse(
  existing?: FetchCommunityPaginatedTasksApiResponse,
  incoming?: FetchCommunityPaginatedTasksApiResponse
): FetchCommunityPaginatedTasksApiResponse | undefined {
  if (!incoming) {
    return existing;
  }

  let existingTaskSummary: Array<CommunityTaskSummary> = [];
  if (existing) {
    const { tasksSummary } = existing;
    if (Array.isArray(tasksSummary)) {
      existingTaskSummary = tasksSummary;
    }
  }

  let { tasksSummary: incomingTaskSummary } = incoming;
  if (!Array.isArray(incomingTaskSummary)) {
    incomingTaskSummary = [];
  }

  return {
    tasksSummary: existingTaskSummary.concat(incomingTaskSummary),
    // overwrite existing pagination variables
    nextPageKey: incoming.nextPageKey,
    __typename: incoming.__typename,
  };
}
