import React, { ForwardedRef, forwardRef } from "react";
import { node, string, func, oneOfType } from "prop-types";
import { pickStylesForRowAlignBasedOnParams } from "../../utils/helpers/row-column-align";
import styles from "./RowAlign.module.scss";
import { DisplaySize } from "../../utils/types/component-configs";

type RowAlignChildProps = {
  classes: string;
  wrapSpaceInRem?: number;
  ref?: ForwardedRef<HTMLDivElement>;
};

type RowAlignExtraProps = {
  classes?: string;
  children: React.ReactNode | ((props: RowAlignChildProps) => JSX.Element);
};

export type RowAlignBaseProps = {
  verticalAlign?: string;
  horizontalAlign?: string;
  wrap?: boolean;
  wrapReverse?: boolean;
  wrapSpace?: string;
  wrapHorizontalSpace?: boolean;
  wrapVerticalSpace?: boolean;
  balanceVerticalWrapSpace?: boolean;
  balanceHorizontalWrapSpace?: boolean;
};

const RowAlignForwardRef = forwardRef<
  HTMLDivElement,
  RowAlignBaseProps & RowAlignExtraProps
>(function RowAlign(
  {
    children,
    verticalAlign,
    horizontalAlign,
    classes,
    wrap,
    wrapReverse,
    wrapHorizontalSpace,
    wrapVerticalSpace,
    wrapSpace,
    balanceHorizontalWrapSpace,
    balanceVerticalWrapSpace,
  },
  ref
) {
  let className = pickStylesForRowAlignBasedOnParams(
    {
      verticalAlign,
      horizontalAlign,
      wrap,
      wrapReverse,
      wrapSpace,
      wrapHorizontalSpace,
      wrapVerticalSpace,
      balanceHorizontalWrapSpace,
      balanceVerticalWrapSpace,
    },
    styles
  );

  if (classes) {
    className += ` ${classes}`;
  }

  if (typeof children === "function") {
    let currentWrapSpace: number | undefined;
    if (wrap) {
      switch (wrapSpace) {
        case DisplaySize.LARGE:
          currentWrapSpace = parseFloat(styles.wrapMarginLarge);
          break;
        case DisplaySize.MEDIUM:
          currentWrapSpace = parseFloat(styles.wrapMarginMedium);
          break;
        default:
          currentWrapSpace = parseFloat(styles.wrapMarginSmall);
          break;
      }
    }
    return children({
      classes: className,
      wrapSpaceInRem: currentWrapSpace,
      ref,
    });
  }

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
});

export default RowAlignForwardRef;

RowAlignForwardRef.propTypes = {
  children: oneOfType([node, func]).isRequired,
  verticalAlign: string,
  horizontalAlign: string,
  classes: string,
};
