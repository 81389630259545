import { gql } from "@apollo/client/core";
import {
  LogoutSessionMutationResponse,
  RefreshTokenQueryResponse,
} from "../../../../graphql/types/user";
import {
  LogoutSessionMutationInput,
  RefreshTokenMutationInput,
} from "../../../../graphql/types/user-input";

export type RefreshTokenOperationInput = {
  sessionData: RefreshTokenMutationInput;
};

type RefreshTokenApiResponse = {
  accessToken: string;
  refreshToken: string;
  emailVerification?: boolean;
};

export type RefreshTokenOperationResponse =
  RefreshTokenQueryResponse<RefreshTokenApiResponse>;

export const REFRESH_TOKEN_USER_SESSION = gql`
  mutation RefreshSessionToken($sessionData: RefreshTokenInput!) {
    refreshToken(input: $sessionData) {
      accessToken
      refreshToken
      emailVerification
    }
  }
`;

export type LogoutSessionOperationInput = {
  sessionData: LogoutSessionMutationInput;
};

type LogoutSessionApiResponse = {
  success?: boolean;
};

export type LogoutSessionOperationResponse =
  LogoutSessionMutationResponse<LogoutSessionApiResponse>;

export const LOGOUT_USER_SESSION = gql`
  mutation LogoutUserSession($sessionData: LogoutSessionInput!) {
    logoutSession(input: $sessionData) {
      success
    }
  }
`;
