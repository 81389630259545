export const ROOT_GET_COMMUNITY_TASKS_SUMMARY = "getCommunityTasksSummary";

export const ROOT_GET_COMMUNITY_TASK_DETAILS = "getCommunityTask";

export const ROOT_GET_ACTIVITY_MAPPERS_LIST = "getActivityMappers";

export const ROOT_GET_DASHBOARD_DATA = "dashboard";

export const ROOT_GET_USER_TASKS_SUMMARY = "getUserTasksSummary";

export const ROOT_GET_USER_TASK_DETAILS = "getUserTask";

export const ROOT_GET_USER_EMAIL_VERIFICATION_DETAILS =
  "getUserEmailVerificationDetails";

export const ROOT_QUERY = "ROOT_QUERY";
