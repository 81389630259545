import { LoadStatus } from "../../utils/types/component-configs";
import {
  getTokenFromNativeStorage,
  refreshTokenCheckProgress,
  saveTokenInCache,
} from "../../utils/helpers/auth-helper";
import { clearNativeStorage } from "../../utils/helpers/storage-helpers";

export function rehydrateTokenToCache() {
  const { accessToken, refreshToken } = getTokenFromNativeStorage();
  if (!accessToken || !refreshToken) {
    clearNativeStorage();
    return;
  }

  // TokenManager will start the process as soon as it loads but
  // the AuthHOC might read the cache first, hence push refresh progress
  // state so that the AuthHOC can simply check the load progress.
  // The flow is created such that on load the token must be refreshed and
  // only then the operations must proceed, hence only the status should be set here.
  // The remaining process will be taken care of by TokenManager
  refreshTokenCheckProgress({ status: LoadStatus.PROGRESS });
  saveTokenInCache(accessToken);
}
