// Important: Separating this from the remaining filter config so that the icons
// don't get included in the bundle if any of these constants are referenced

import { ActivityCategory } from "../../graphql/types/activity-mappers";
import { ImpactCategoryInput } from "../types/task-activity-input-types";

export const ACTIVITY_CATEGORY_LABELS = {
  [ActivityCategory.TRAVEL]: "Travel",
  [ActivityCategory.FOOD]: "Food",
  [ActivityCategory.UTILITIES]: "Utilities",
  [ActivityCategory.PERSONAL_HYGIENE]: "Personal hygiene",
};

export const IMPACT_CATEGORY_LABELS = {
  [ImpactCategoryInput.ALL]: "All",
  [ImpactCategoryInput.CARBON]: "Carbon",
  [ImpactCategoryInput.WATER]: "Water",
  [ImpactCategoryInput.COMBINED_WASTE]: "Waste",
  [ImpactCategoryInput.PLASTIC_WASTE]: "Plastic waste",
  [ImpactCategoryInput.MEDICAL_WASTE]: "Medical waste",
  [ImpactCategoryInput.DRY_WASTE]: "Dry waste",
  [ImpactCategoryInput.WET_WASTE]: "Wet waste",
  [ImpactCategoryInput.E_WASTE]: "Electronic waste",
};
