import { Alignment, DisplaySize } from "../types/component-configs";
import { ColumnAlignBaseProps } from "../../atoms/column-align/ColumnAlign";
import { RowAlignBaseProps } from "../../atoms/row-align/RowAlign";

export function pickStylesForRowAlignBasedOnParams(
  {
    verticalAlign,
    horizontalAlign,
    wrap,
    wrapReverse,
    wrapSpace,
    wrapHorizontalSpace,
    balanceHorizontalWrapSpace,
    wrapVerticalSpace,
    balanceVerticalWrapSpace,
  }: RowAlignBaseProps,
  styles: { [className: string]: string }
) {
  const classNames = [styles.row];
  switch (verticalAlign) {
    case Alignment.CENTER:
      classNames.push(styles.verticalCenter);
      break;
    case Alignment.START:
      classNames.push(styles.verticalStart);
      break;
    case Alignment.END:
      classNames.push(styles.verticalEnd);
      break;
    default:
      break;
  }

  switch (horizontalAlign) {
    case Alignment.CENTER:
      classNames.push(styles.horizontalCenter);
      break;
    case Alignment.START:
      classNames.push(styles.horizontalStart);
      break;
    case Alignment.END:
      classNames.push(styles.horizontalEnd);
      break;
    case Alignment.BETWEEN:
      classNames.push(styles.horizontalBetween);
      break;
    default:
      break;
  }

  if (wrap || wrapReverse) {
    classNames.push(styles.rowWrap);
    if (wrapReverse) {
      classNames.pop();
      classNames.push(styles.rowWrapReverse);
    }

    if (wrapHorizontalSpace) {
      classNames.push(styles.wrapHorizontalSpace);

      if (balanceHorizontalWrapSpace) {
        classNames.push(styles.balanceHorizontalWrapSpace);
      }
    }

    if (wrapVerticalSpace) {
      classNames.push(styles.wrapVerticalSpace);

      if (balanceVerticalWrapSpace) {
        classNames.push(styles.balanceVerticalWrapSpace);
      }
    }

    switch (wrapSpace) {
      case DisplaySize.MEDIUM:
        classNames.push(styles.marginMedium);
        break;
      case DisplaySize.LARGE:
        classNames.push(styles.marginLarge);
        break;
      default:
        classNames.push(styles.marginSmall);
        break;
    }
  }

  return classNames.join(" ");
}

export function pickStylesForColumnAlignBasedOnParams(
  {
    verticalAlign,
    horizontalAlign,
    fullHeight,
    fitHeight,
    shouldScroll,
  }: ColumnAlignBaseProps,
  styles: { [className: string]: string }
) {
  const classNames = [styles.column];

  switch (horizontalAlign) {
    case Alignment.CENTER:
      classNames.push(styles.horizontalCenter);
      break;
    case Alignment.START:
      classNames.push(styles.horizontalStart);
      break;
    case Alignment.END:
      classNames.push(styles.horizontalEnd);
      break;
    default:
      break;
  }

  switch (verticalAlign) {
    case Alignment.CENTER:
      classNames.push(styles.verticalCenter);
      break;
    case Alignment.START:
      classNames.push(styles.verticalStart);
      break;
    case Alignment.END:
      classNames.push(styles.verticalEnd);
      break;
    case Alignment.BETWEEN:
      classNames.push(styles.verticalBetween);
      break;
    default:
      break;
  }

  if (fullHeight) {
    classNames.push(styles.fullHeight);
  }

  if (fitHeight) {
    classNames.push(styles.fitHeight);
  }

  if (shouldScroll) {
    classNames.push(styles.makeScrollable);
  }

  return classNames.join(" ");
}
