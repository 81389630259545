import { useEffect, useRef, useState } from "react";

type UseTimerParams = {
  countdownSeconds: number;
  startTimer: boolean;
  nextUpdateAtSeconds?: number;
  onCountdownComplete?: () => void;
};

type UseTimerReturn = {
  secondsLeft: number;
};

export default function useTimer({
  startTimer,
  countdownSeconds,
  onCountdownComplete,
  nextUpdateAtSeconds = 1,
}: UseTimerParams): UseTimerReturn {
  const [timerLeft, setTimerLeft] = useState(Math.max(countdownSeconds, 0));
  const intervalRef = useRef<number | undefined>();

  // Store the function in ref so that the effect doesn't run unnecessarily
  const coutdownCompleteRef =
    useRef<UseTimerParams["onCountdownComplete"]>(onCountdownComplete);
  coutdownCompleteRef.current = onCountdownComplete;

  useEffect(() => {
    if (timerLeft > 0) {
      return;
    }

    window.clearInterval(intervalRef.current);
    coutdownCompleteRef.current?.();
  }, [timerLeft]);

  useEffect(() => {
    if (!startTimer) {
      return;
    }

    setTimerLeft(countdownSeconds);
    intervalRef.current = window.setInterval(() => {
      setTimerLeft((prevValue) => Math.max(0, prevValue - nextUpdateAtSeconds));
    }, nextUpdateAtSeconds * 1000);

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [countdownSeconds, nextUpdateAtSeconds, startTimer]);

  return { secondsLeft: timerLeft };
}
