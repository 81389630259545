import {
  BaseGraphQlTypeData,
  GraphQlApiResponseType,
} from "../../utils/types/app-types";
import { ROOT_GET_ACTIVITY_MAPPERS_LIST } from "../constants/type-root-fields";

export enum ImpactCategory {
  CARBON = "CARBON",
  WATER = "WATER",
  DRY_WASTE = "DRY_WASTE",
  WET_WASTE = "WET_WASTE",
  E_WASTE = "E_WASTE",
  MEDICAL_WASTE = "MEDICAL_WASTE",
  PLASTIC_WASTE = "PLASTIC_WASTE",
}

export enum ActivityCategory {
  TRAVEL = "TRAVEL",
  FOOD = "FOOD",
  UTILITIES = "UTILITIES",
  PERSONAL_HYGIENE = "PERSONAL_HYGIENE",
}

export enum ActivityType {
  IMPACT = "IMPACT",
  VIEW = "VIEW",
}

export interface ActivityImpact extends BaseGraphQlTypeData {
  impactId: string;
  impactCategory: ImpactCategory;
  value: number;
}

export interface ActivityUnits extends BaseGraphQlTypeData {
  unitId: string;
  value?: GraphQlApiResponseType<number>;
  flip?: GraphQlApiResponseType<boolean>;
}

export interface ActivityImpactDetails extends BaseGraphQlTypeData {
  source?: string;
  activityImpacts: Array<ActivityImpact>;
  activityUnits?: GraphQlApiResponseType<Array<ActivityUnits>>;
}

// Important: unused and incomplete schema; adding as reference for future implementation
export interface ActivityViewDetails extends BaseGraphQlTypeData {
  viewType: string;
}

export interface ActivityDetails extends BaseGraphQlTypeData {
  activityId: string;
  activityLabel: string;
  activityType: ActivityType;
  activityCategory: ActivityCategory;
  activityImpactDetails?: GraphQlApiResponseType<ActivityImpactDetails>;
  activityViewDetails?: GraphQlApiResponseType<ActivityViewDetails>;
}

export interface FetchActivityMappersQueryResponse<T> {
  [ROOT_GET_ACTIVITY_MAPPERS_LIST]: GraphQlApiResponseType<T>;
}
