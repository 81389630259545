import { COMMUNITY_URL, HOME_PAGE_URL } from "../constants/page";
import {
  RESIZE_OBSERVER_POLYFILL_PATH,
  INTERSECTION_OBSERVER_POLYFILL_PATH,
} from "../constants/polyfil-paths";
import { PolyFillCheckerItem } from "../types/app-types";
import { matchRoute } from "./route-match";

const ROUTE_POLYFILL_MAP = new Map<string, { polyFill: PolyFillCheckerItem[] }>(
  [
    [
      COMMUNITY_URL,
      {
        polyFill: [
          {
            polyfillChecker: resizeObserverPolyfillCheck,
            polyfillScriptPath: RESIZE_OBSERVER_POLYFILL_PATH,
          },
        ],
      },
    ],
    [
      HOME_PAGE_URL,
      {
        polyFill: [
          {
            polyfillChecker: intersectionObserverPolyfillCheck,
            polyfillScriptPath: INTERSECTION_OBSERVER_POLYFILL_PATH,
          },
        ],
      },
    ],
  ]
);

export function objectValuesPolyfillCheck() {
  return !Object.values;
}

export function intersectionObserverPolyfillCheck() {
  try {
    return !(
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype &&
      "isIntersecting" in window.IntersectionObserverEntry.prototype
    );
  } catch (error) {
    // Not in a browser environment
    return false;
  }
}

export function resizeObserverPolyfillCheck() {
  try {
    return !window.ResizeObserver;
  } catch (error) {
    // Not in a browser environment
    return false;
  }
}

export function getPolyFillForRoute(currentRoute: string): {
  polyFill: PolyFillCheckerItem[];
} {
  for (const [route, polyFillData] of ROUTE_POLYFILL_MAP) {
    if (matchRoute(route, currentRoute)) {
      return polyFillData;
    }
  }

  return {
    polyFill: [],
  };
}

export function getPolyFillForRoutePath(routePath: string) {
  return ROUTE_POLYFILL_MAP.get(routePath)?.polyFill || [];
}
